import React, { useEffect, useState, useRef } from "react";
import CustomTitle from "./CustomTitle";
import cimg1 from "./assets/img/home/banners/banner with text-01.jpg";
import cimg2 from "./assets/img/home/banners/banner with text-02.jpg";
import cimg5 from "./assets/img/home/banners/banner-with-welcome1.jpg";

import slider1 from "./assets/img/home/banners/new/beginning_1.jpg";
import slider1m from "./assets/img/home/banners/new/beginning_1_m.jpg";
import slider2 from "./assets/img/home/banners/new/fullfill_2.jpg";
import slider2m from "./assets/img/home/banners/new/fullfill_2_m.jpg";
import slider3 from "./assets/img/home/banners/new/miracle_3.jpg";
import slider3m from "./assets/img/home/banners/new/miracle_3_m.jpg";
import slider4 from "./assets/img/home/banners/new/website-banner-fertility-new.jpg";
import slider4m from "./assets/img/home/banners/new/website-banner-fertility-mb.jpg";
import slider5 from "./assets/img/home/banners/new/branch_5.jpg";
import slider5m from "./assets/img/home/banners/new/branch_5_m.jpg";
import slider6 from "./assets/img/home/banners/new/slider6.jpg";

import doctorimage from "./assets/img/home/service-images/doctor.png";
import "./Home.css";
import img1 from "./assets/img/icons/home-page/ovu.webp";
import img2 from "./assets/img/icons/home-page/IUI.webp";
import img3 from "./assets/img/icons/home-page/ivf1.webp";
import img4 from "./assets/img/icons/home-page/ICSI.webp";
import blog2 from "./assets/img/home/blog-images/blog-pcos.jpg";
import blog3 from "./assets/img/home/blog-images/blog-por.jpg";
import blog1 from "./assets/img/home/blog-images/blog-andrology.jpg";
import blog4 from "./assets/img/home/blog-images/common-myths-banner.jpg";
import blog7 from "../../src/components/assets/img/home/blog-images/age-fertility.jpg";
import blog8 from "../../src/components/assets/img/home/blog-images/blog-diet-banner.jpg";
import blog9 from "../../src/components/assets/img/home/blog-images/common-myths-banner.jpg";
import blog10 from "../../src/components/assets/img/home/blog-images/blog5.jpg";
import OwlCarousel from "react-owl-carousel";
import "../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { Card, Button, Accordion } from "react-bootstrap";
import backimg from "./assets/img/home/accordian/accordian_bg.jpg";
import { useLocation, Link } from "react-router-dom";
import HomeSchema from "./helper/SEO/Home";

<script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>;

export default function Home() {
  const [width, setWidth] = useState(window.innerWidth);
  const carouselRef = useRef(null);
  const testimonialRef = useRef(null);

  const next = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const prev = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };
  useEffect(() => {
    const resizer = window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", resizer);
    };
  });

  const location = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  function show(id1, id2, id3, id4, id5, id6, id7, id8) {
    document.getElementById(id1).style.display = "none";
    document.getElementById(id2).style.display = "block";
    document.getElementById(id3).style.display = "none";
    document.getElementById(id4).style.display = "none";
    document.getElementById(id5).style.display = "none";
    document.getElementById(id6).style.display = "block";
    document.getElementById(id7).style.display = "block";
    document.getElementById(id8).style.display = "block";
  }
  function hide(id1, id2) {
    document.getElementById(id2).style.display = "none";
    document.getElementById(id1).style.display = "block";
  }
  const helmetDescriptions = {
    titleName:
      "Best Fertility Hospital Bangalore | Fertility Treatment in Bangalore",
    Description:
      "Kangaroo Care Fertility is one of the leading and trusted fertility clinic in Bangalore facilitating advanced fertility treatments under a single roof",
  };

  const [scroll, setScroll] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const resizer = window.addEventListener("resize", () => {
      // setresize(window.innerWidth);
      setScroll(window.innerWidth);
    });

    return () => {
      window.removeEventListener("resize", resizer);
    };
  }, []);
  const nextBanner = () => {
    if (carouselRef.current) {
      carouselRef.current.next();
    }
  };

  const prevBanner = () => {
    if (carouselRef.current) {
      carouselRef.current.prev();
    }
  };
  const nextTestimonial = () => {
    if (testimonialRef.current) {
      testimonialRef.current.next();
    }
  };

  const prevTestimonial = () => {
    if (testimonialRef.current) {
      testimonialRef.current.prev();
    }
  };
  const testimonailOptions = {
    responsive: {
      0: {
        items: 1,
      },
      // 650: {
      //   items: 2,
      // },
      // 900: {
      //   items: 3,
      // },
    },
  };
  return (
    <>
      <CustomTitle customValue={helmetDescriptions} />
      <div className="banner_wrapper">
        {/* <img
          src={slider6}
          className="d-block w-100"
          alt="Kangaroo Care Fertility Hospital Reception Area "
        /> */}
        <OwlCarousel
          ref={carouselRef}
          items={1}
          className="owl-carousel-blog banner-slider"
          data-aos="fade-up"
          data-aos-delay="100"
          loop
          autoplay={true}
          autoplayTimeout={7000}
          margin={8}
          dots={true}
          nav={false}
        >
          {/* <div className="banner-item">
            {width > 650 ? (
              <>
                <img
                  src={slider6}
                  className="d-block w-100"
                  alt="Kangaroo Care Fertility Hospital Reception Area "
                />
              </>
            ) : (
              <>
                {" "}
                <img
                  src={slider1m}
                  className="d-block w-100"
                  alt="Kangaroo Care Fertility Hospital Reception Area "
                />
              </>
            )}
          </div> */}
          <div className="banner-item">
            {width > 650 ? (
              <>
                <img
                  src={slider1}
                  className="d-block w-100"
                  alt="Kangaroo Care Fertility Hospital Reception Area "
                />
              </>
            ) : (
              <>
                <img
                  src={slider1m}
                  className="d-block w-100"
                  alt="Kangaroo Care Fertility Hospital Reception Area "
                />
              </>
            )}
          </div>
          <div className="banner-item">
            {width > 650 ? (
              <>
                <img
                  src={slider2}
                  className="d-block w-100"
                  alt="Kangaroo Care Fertility Hospital Reception Area "
                />
              </>
            ) : (
              <>
                <img
                  src={slider2m}
                  className="d-block w-100"
                  alt="Kangaroo Care Fertility Hospital Reception Area "
                />
              </>
            )}
          </div>
          <div className="banner-item">
            {width > 650 ? (
              <>
                <img
                  src={slider3}
                  className="d-block w-100"
                  alt="Kangaroo Care Fertility Hospital Reception Area "
                />
              </>
            ) : (
              <>
                <img
                  src={slider3m}
                  className="d-block w-100"
                  alt="Kangaroo Care Fertility Hospital Reception Area "
                />
              </>
            )}
          </div>
          <div className="banner-item">
            {width > 650 ? (
              <>
                <img
                  src={slider4}
                  className="d-block w-100"
                  alt="Kangaroo Care Fertility Hospital Reception Area "
                />
              </>
            ) : (
              <>
                <img
                  src={slider4m}
                  className="d-block w-100"
                  alt="Kangaroo Care Fertility Hospital Reception Area "
                />
              </>
            )}
          </div>
        </OwlCarousel>
        <div className="banner_custom_nav">
          <button onClick={prevBanner}>
            <i class="fas fa-chevron-left"></i>
          </button>
          <button onClick={nextBanner}>
            <i class="fas fa-chevron-right"></i>
          </button>
        </div>
      </div>
      {/* cards */}

      <div
        className="container-fluid"
        style={{ backgroundColor: "#ececed", padding: "10px" }}
        data-aos="fade-up"
      >
        <div className="text-center">
          {" "}
          <br />
          <h1 className="heading1">Leading Fertility Centers in</h1>
          <h2 className="heading1">
            <strong>Bangalore | Mysore | Ramanagara</strong>
          </h2>
        </div>
        <div className="container my-4" id="homeHavingCard">
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-2">
              <div className="card pt-4">
                <div className="card-header">
                  <h2 className="heading2 card-title text-center pb-2">
                    Ovulation Induction
                  </h2>
                  <img
                    src={img1}
                    style={{ height: "100px", width: "100px" }}
                    className="align-self-center card-img-top"
                    alt="Injection and Medicine icons"
                  />
                </div>
                <div className="card-body">
                  <p className="card-text py-3" style={{ textAlign: "center" }}>
                    Medications are given to induce ovulation, followed by timed
                    intercourse or artificial insemination for better chances of
                    conception.
                  </p>
                  <div className="text-center">
                    <Link
                      to="/treatments/ovulation-induction"
                      // className=" btn btn-lg services-btn btn-color-services"
                      className="btn "
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-2">
              <div className="card pt-4">
                <div className="card-header">
                  <h2 className="heading2 card-title text-center pb-2">IUI</h2>
                  <img
                    src={img2}
                    style={{ height: "100px", width: "100px" }}
                    className="align-self-center card-img-top"
                    alt="Uterus icon"
                  />
                </div>
                <div className="card-body">
                  <p className="card-text py-1" style={{ textAlign: "center" }}>
                    IUI facilitates fertilization as the sperm is directly
                    inserted into the uterus whereby it surpasses the hostile
                    cervical factors and its journey towards the egg is
                    shortened.
                  </p>
                  <div className="text-center">
                    <Link className="btn " to="/treatments/intrauterine">
                      Read More
                    </Link>
                  </div>
                  {/* <div className="text-center">
                    <Link
                      to="/treatments/intracytoplasmic"
                      className="btn btn-lg btn-primary btn-color-services"
                    >
                      Read More
                    </Link>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-2">
              <div className="card pt-4">
                <div className="card-header">
                  <h2 className="heading2 card-title text-center pb-2">IVF</h2>
                  <img
                    src={img3}
                    style={{ height: "100px", width: "100px" }}
                    className="align-self-center card-img-top"
                    alt="sperms moving towards egg icon"
                  />
                </div>
                <div className="card-body">
                  <p className="card-text py-3" style={{ textAlign: "center" }}>
                    A type of assisted reproduction where the egg and the sperms
                    are allowed to fertilize naturally by placing them in a
                    petri dish in the lab.
                  </p>
                  <div className="text-center">
                    <Link
                      to="/treatments/invitro-fertilization"
                      className="btn "
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 mb-2">
              <div className="card pt-4">
                <div className="card-header">
                  <h2 className="heading2 card-title text-center pb-2">ICSI</h2>
                  <img
                    src={img4}
                    style={{ height: "100px", width: "100px" }}
                    className="align-self-center card-img-top"
                    alt="sperm is injected into a egg"
                  />
                </div>
                <div className="card-body">
                  <p className="card-text py-3" style={{ textAlign: "center" }}>
                    ICSI, another type of assisted reproduction, improves the
                    chances of fertilization as the sperm is directly injected
                    into the egg.
                  </p>
                  <div className="text-center">
                    <Link to="/treatments/intracytoplasmic" className="btn ">
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* welcome to the service below the services*/}
      {/* welcome to the service section */}
      <div
        className="welcome-container d-lg-flex"
        id="welc-cont"
        data-aos="fade-up"
      >
        {" "}
        <div className="welcome-container3 ">
          <img
            className="img-size"
            src={doctorimage}
            alt="Dr. Shekar Subbaiah Neonatologist & CEO"
          />
          <p className="text-center p-2" style={{ fontWeight: "bold" }}>
            <strong>
              DR. SHEKAR SUBBAIAH <br />
              <small>NEONATOLOGIST &amp; CEO</small>
            </strong>
          </p>
        </div>
        <div className="welcome-container1 pe-sm-5 pe-0 mb-2">
          <h2 className="text-blue">Welcome to Kangaroo Care Fertility</h2>

          <p className=" my-sm-4 my-2" id="welcome-firtility-text">
            {" "}
            Parenthood is a cherished milestone in your life journey. Sometimes,
            the path to parenthood may require a helping hand, especially when
            natural conception seems challenging. That's where Kangaroo Care
            Fertility steps in.{" "}
          </p>
          <p className=" my-sm-4 my-2" id="welcome-firtility-text">
            {" "}
            Our mission is simple yet profound: to guide you toward successful
            parenthood by offering comprehensive fertility treatment services in
            one place. We recognize the challenges that come with fertility
            treatment, and our goal is to make this journey as pleasant and
            stress-free as possible for you.{" "}
          </p>
          <p className=" my-sm-4 my-2" id="welcome-firtility-text">
            {" "}
            At Kangaroo Care Fertility, we believe in personalized care tailored
            to your needs. Our team of experts is dedicated to providing
            compassionate support and utilizing technologies to maximize your
            chances of conception.{" "}
          </p>
          <Link target="_blank" to="/contact" data-aos="fade-up">
            <Link to="/contact" className="button topbar-btn-color-contact">
              Contact Us
            </Link>
          </Link>
        </div>
      </div>
      {/* //accordian */}
      {/* <div>
        <div
          className="container-fluid accordion-align  "
          style={{
            backgroundImage: "url(" + backimg + ")",
            backgroundSize: "cover",
            float: "left",
          }}
        >
          <div className="acdn container offset-md-1  col-md-8 col-12 py-5 home_accordian">
            <div className="container my-2 pb-4 ">
              <h2 className="heading2">Fertility Services</h2>
            </div>

            <Accordion defaultActiveKey="">
              <div className="acdn">
                <Card.Header
                  className="acdn-card"
                  style={{
                    backgroundColor: "rgba(245, 245, 245, 0)",
                    border: "none",
                  }}
                >
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    id="a1"
                    style={{ float: "left" }}
                    onClick={() =>
                      show("a1", "m1", "m2", "m3", "m4", "a2", "a3", "a4")
                    }
                  >
                    <AddCircleIcon className="acdnIcon" />
                  </Accordion.Toggle>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="0"
                    id="m1"
                    style={{ float: "left", display: "none" }}
                    onClick={() => hide("a1", "m1")}
                  >
                    <RemoveCircleIcon className="acdnIcon" />
                  </Accordion.Toggle>
                  <h3>Fertility Testing </h3>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                  <Card.Body
                    className="mx-4 col-12"
                    style={{ color: "#383837" }}
                  >
                    <p>
                      <Link to="/treatments/fertility-testing-for-men">
                        &gt; Male Fertility
                      </Link>
                      <br />
                      <Link to="/treatments/fertility-testing-for-women">
                        &gt; Female Fertility
                      </Link>
                      <br />
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </div>
              <div>
                <Card.Header
                  className="acdn-card"
                  style={{
                    backgroundColor: "rgba(245, 245, 245, 0)",
                    border: "none",
                  }}
                >
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    id="a2"
                    style={{ float: "left" }}
                    onClick={() =>
                      show("a2", "m2", "m1", "m3", "m4", "a1", "a3", "a4")
                    }
                  >
                    <AddCircleIcon className="acdnIcon" />
                  </Accordion.Toggle>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="1"
                    id="m2"
                    style={{ float: "left", display: "none" }}
                    onClick={() => hide("a2", "m2")}
                  >
                    <RemoveCircleIcon className="acdnIcon" />
                  </Accordion.Toggle>
                  <h3>Infertility Treatment </h3>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body
                    className="mx-4 col-12"
                    style={{ color: "#383837" }}
                  >
                    <p>
                      <Link to="/treatments/ovulation-induction">
                        &gt; Ovulation Induction
                      </Link>
                      <br />
                      <Link to="/treatments/intrauterine">&gt; IUI</Link>
                      <br />
                      <Link to="/treatments/invitro-fertilization">
                        &gt; IVF
                      </Link>
                      <br />
                      <Link to="/treatments/intracytoplasmic">&gt; ICSI</Link>
                      <br />
                      <Link to="/treatments/donor">&gt; Donor</Link>
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </div>
              <div>
                <Card.Header
                  className="acdn-card"
                  style={{
                    backgroundColor: "rgba(245, 245, 245, 0)",
                    border: "none",
                  }}
                >
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    id="a3"
                    style={{ float: "left" }}
                    onClick={() =>
                      show("a3", "m3", "m1", "m2", "m4", "a1", "a2", "a4")
                    }
                  >
                    <AddCircleIcon className="acdnIcon" />
                  </Accordion.Toggle>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="2"
                    id="m3"
                    style={{ float: "left", display: "none" }}
                    onClick={() => hide("a3", "m3")}
                  >
                    <RemoveCircleIcon className="acdnIcon" />
                  </Accordion.Toggle>
                  <h3>Andrology</h3>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body
                    className="mx-4 col-12"
                    style={{ color: "#383837" }}
                  >
                    <p>
                      <Link to="/treatments/varicocele">&gt; Varicocele</Link>
                      <br />
                      <Link to="/treatments/tesa-pesa"> &gt; TESA-PESA</Link>
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </div>
              <div>
                <Card.Header
                  className="acdn-card"
                  style={{
                    backgroundColor: "rgba(245, 245, 245, 0)",
                    border: "none",
                  }}
                >
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="3"
                    id="a4"
                    style={{ float: "left" }}
                    onClick={() =>
                      show("a4", "m4", "m1", "m2", "m3", "a1", "a2", "a3")
                    }
                  >
                    <AddCircleIcon className="acdnIcon" />
                  </Accordion.Toggle>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey="3"
                    id="m4"
                    style={{ float: "left", display: "none" }}
                    onClick={() => hide("a4", "m4")}
                  >
                    <RemoveCircleIcon className="acdnIcon" />
                  </Accordion.Toggle>
                  <h3>Genetic Counselling and Testing</h3>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body
                    className="mx-4 col-12"
                    style={{ color: "#383837" }}
                  >
                    <p>
                      <Link to="/treatments/genetic-counselling-and-testing">
                        &gt; PGT
                      </Link>
                      <br />
                    </p>
                  </Card.Body>
                </Accordion.Collapse>
              </div>
            </Accordion>
          </div>
        </div>
      </div> */}

      {/* Testimonials */}
      <div className="testimonials-section">
        <div className="testimonials-section-top-text">
          <h2>Testimonials</h2>
        </div>

        <div className="testimonial_items_">
          <div
            className="container testimonial_items_wrapper"
            id="testimonial_google_home"
          >
            <OwlCarousel
              ref={testimonialRef}
              items={1}
              className="owl-carousel-blog testimonials-slider"
              data-aos="fade-up"
              data-aos-delay="100"
              loop
              autoplay={true}
              autoplayTimeout={4000}
              margin={8}
              dots={true}
              nav={false}
              navText={[
                '<i className="icofont-simple-up newStyle"></i>',
                '<i className="icofont-simple-up"></i>',
              ]}
              {...testimonailOptions}
            >
              <div className="testimonials-item">
                <div className="image_container">
                  <div className="testimonial_google ">
                    <img src="/google-01.png" />
                  </div>
                  <div className="testimonial_star">
                    <img src="/5-star.png" />
                  </div>
                </div>
                <p id="kindlyLeft">
                  We consider overselves most fortunate to have consulted in
                  Kangaroo Care Fertility center Bangalore. The facility of
                  Kangaroo Care Fertility is wonderful, the medical staff is
                  well experienced and caring, and the adminstrative staff is
                  extreamly pleasant and efficient. We were beyond impressed
                  with the kind of care we got care and experience. Thanks to
                  all doctors and staff Kangaroo Care Fertility.
                </p>
                <p className="testimonials-item-name"> Monica & Vinay</p>
              </div>
              <div className="testimonials-item">
                <div className="image_container">
                  <div className="testimonial_google ">
                    <img src="/google-01.png" />
                  </div>
                  <div className="testimonial_star">
                    <img src="/5-star.png" />
                  </div>
                </div>
                <p>
                  We are thankful to Kangaroo Care, Fertility center for the
                  best services. The doctors are very good and the team is very
                  honored and polite enough to keep us informed about all The
                  processes and procedures. All the facilities available under
                  one root and ambience maintained, neat and clean.
                </p>
                <p className="testimonials-item-name"> Shnuddha Reddy </p>
              </div>
              <div className="testimonials-item">
                <div className="image_container">
                  <div className="testimonial_google ">
                    <img src="/google-01.png" />
                  </div>
                  <div className="testimonial_star">
                    <img src="/5-star.png" />
                  </div>
                </div>
                <p>
                  The overall experience from Kangaroocare fertility was really
                  good. Every doctors and staff are very caring and supportive.
                  They will guide you like mothers and care for every women.
                  Thanks again and recommended others also to this hospital
                </p>
                <p className="testimonials-item-name"> Jyothi, Hari </p>
              </div>
            </OwlCarousel>
            <div className="testimonial_custom_nav">
              <button onClick={prevTestimonial}>
                <i class="fas fa-chevron-left"></i>
              </button>
              <button onClick={nextTestimonial}>
                <i class="fas fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">
                Best Fertility Treatement at Kangaroo Care{" "}
              </h2>
              {/* <p>
                At Kangaroo Care Fertility, we provide opportunities for the
                couples to embrace parenthood by providing the best infertility
                treatment from our fertility specialists. We deliver a range of
                facilities and services for couples to overcome fertility
                related problems which makes Kangaroo Care Fertility the best
                fertility clinic in Bangalore.
              </p> */}
              <p>
                At Kangaroo Care Fertility, we provide opportunities for couples
                to embrace parenthood by providing the best fertility treatment
                from highly qualified and experienced fertility specialists.
                <br />
                We are equipped with state-of-art laboratories and advanced
                technologies to deliver a range of facilities and treatments
                like &nbsp;
                <Link to="/treatments/ovulation-induction">
                  Ovulation Induction,
                </Link>
                <Link to="/treatments/invitro-fertilization">IVF,</Link>{" "}
                <Link to="/treatments/intrauterine">IUI,</Link>{" "}
                <Link to="/treatments/intracytoplasmic">ICSI,</Link>{" "}
                <Link to="/treatments/tesa-pesa">TESA & PESA.</Link> We have the
                best fertility specialists in Bangalore, who understand the
                requirements of our patients and strive to provide the best
                support and treatment for patients.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* //blogs slider */}
      <div className=" blog-slider" data-aos="fade-up">
        <div className="blog-section-top-text">
          <h2 className="text-center heading2 mt-3">Our Blog Feed</h2>
        </div>
        <div>
          <OwlCarousel
            items={2}
            className="owl-carousel blog-slider sliders "
            data-aos="fade-up"
            data-aos-delay="100"
            loop
            autoplay={true}
            autoplayTimeout={6000}
            margin={8}
            dots={true}
            nav={false}
            navText={
              [
                // '<i  id="icofont-simple-up-left"><</i>',
                // '<i  id="icofont-simple-up-right"> ></i>',
              ]
            }
            responsiveclassName={true}
            responsive={{
              0: {
                items: 1,
                nav: true,
              },
              600: {
                items: 1,
                nav: false,
              },
              1000: {
                items: 2,
                nav: true,
                loop: true,
              },
            }}
          >
            <div className="blog-container" data-aos="fade-up">
              <Link to="/media/blogs/age-and-fertility/7" target="_blank">
                <img src={blog7} alt=".." />
                <div className="bottom-left">
                  {/* <p className="date">November 8 2016</p> */}
                  <p className="title">AGE AND FERTILITY.!</p>
                  <p className="link">more info</p>
                </div>
              </Link>
            </div>
            <div className="blog-container" data-aos="fade-up">
              <Link
                to="/media/blogs/best-food-habits-to-enhance-fertility/6"
                target="_blank"
              >
                <img src={blog8} alt="PCOS" />
                <div className="bottom-left">
                  {/* <p className="date">November 8 2016</p> */}
                  <p className="title">Best Food Habits to Enhance Fertility</p>
                  <p className="link">more info</p>
                </div>
              </Link>
            </div>
            <div className="blog-container">
              <Link
                to="/media/blogs/tips-to-boost-your-fertility/5"
                target="_blank"
              >
                <img src={blog9} alt="Egg tray" />
                <div className="bottom-left">
                  {/* <p className="date">November 8 2016</p> */}
                  <p className="title">Tips To Boost Your Fertility</p>
                  <p className="link">more info</p>
                </div>
              </Link>
            </div>
            <div className="blog-container">
              <Link
                to="/media/blogs/common-misconceptions-about-fertility/4"
                target="_blank"
              >
                <img src={blog10} alt="Doctor and patient" />
                <div className="bottom-left">
                  {/* <p className="date">November 8 2016</p> */}
                  <p className="title">
                    Common Misconceptions About Infertility
                  </p>
                  <p className="link">more info</p>
                </div>
              </Link>
            </div>
          </OwlCarousel>
        </div>
      </div>
      <div id="download_app_text">
        <div className="download_app">
          <h3>Download Our App On</h3>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=au.com.turntech.kangaroocare&hl=en_US"
          >
            <img src="/download_app.png" />
          </a>
          <a
            target="_blank"
            href="https://apps.apple.com/in/app/kangaroo-care/id1634423573"
          >
            <img src="/appstore.png" />
          </a>
        </div>
        <div>
          <img src="/qr_code.png" />
        </div>
      </div>
      {/* //sample */}
      <HomeSchema />

      {/* ----------------some heading and para----------- */}
      {/* <div className="container-fluid bg-light ">
        <div className="container">
          <div className="row">
            <div className="col text-center my-sm-5 my-3">
              <h2 className="heading2">
                Best Infertility Hospital in Bangalore{" "}
              </h2>
              <p>
                Kangaroo Care Fertility is one of the best fertility clinics in
                Vijayanagar Bangalore, which is equipped with state-of-art
                techniques and advanced technologies to provide the best
                fertility treatment like{" "}
                <Link to="/treatments/invitro-fertilization">IVF,</Link>{" "}
                <Link to="/treatments/intrauterine">IUI,</Link>{" "}
                <Link to="/treatments/intracytoplasmic">ICSI,</Link>{" "}
                <Link to="/treatments/ovulation-induction">
                  Ovulation Induction,
                </Link>{" "}
                <Link to="/treatments/tesa-pesa">TESA & PESA.</Link> We have the
                best fertility specialists in Bangalore, who understand the
                requirements of our patients and strive to provide the best
                support and treatment for patients.
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

// <div className="container my-4" id="homeHavingCard">
//   <div className="row">
//     <div className="col-lg-3 col-md-6 mb-2">
//       <div className="card pt-4">
//         <h2 className="heading2 card-title text-center pb-2">
//           Ovulation Induction
//         </h2>
//         <img
//           src={img1}
//           style={{ height: "100px", width: "100px" }}
//           className="align-self-center card-img-top"
//           alt="Injection and Medicine icons"
//         />
//         <div className="card-body">
//           <p
//             className="card-text py-3"
//             style={{ textAlign: "justify" }}
//           >
//             Medications are given to induce ovulation, followed by timed
//             intercourse or artificial insemination for better chances of
//             conception.
//           </p>
//           <div className="text-center">
//             <Link
//               to="/treatments/ovulation-induction"
//               className=" btn btn-lg services-btn btn-color-services"
//             >
//               Read More
//             </Link>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div className="col-lg-3 col-md-6 mb-2">
//       <div className="card pt-4">
//         <h2 className="heading2 card-title text-center pb-2">IUI</h2>
//         <img
//           src={img2}
//           style={{ height: "100px", width: "100px" }}
//           className="align-self-center card-img-top"
//           alt="Uterus icon"
//         />
//         <div className="card-body">
//           <p
//             className="card-text py-1"
//             style={{ textAlign: "justify" }}
//           >
//             IUI facilitates fertilization as the sperm is directly
//             inserted into the uterus whereby it surpasses the hostile
//             cervical factors and its journey towards the egg is
//             shortened.
//           </p>
//           <div className="text-center">
//             <Link
//               className="btn btn-primary btn-lg btn-color-services"
//               to="/treatments/intrauterine"
//             >
//               Read More
//             </Link>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div className="col-lg-3 col-md-6 mb-2">
//       <div className="card pt-4">
//         <h2 className="heading2 card-title text-center pb-2">IVF</h2>
//         <img
//           src={img3}
//           style={{ height: "100px", width: "100px" }}
//           className="align-self-center card-img-top"
//           alt="sperms moving towards egg icon"
//         />
//         <div className="card-body">
//           <p
//             className="card-text py-3"
//             style={{ textAlign: "justify" }}
//           >
//             A type of assisted reproduction where the egg and the sperms
//             are allowed to fertilize naturally by placing them in a
//             petri dish in the lab.
//           </p>
//           <div className="text-center">
//             <Link
//               to="/treatments/invitro-fertilization"
//               className="btn btn-lg btn-primary btn-color-services"
//             >
//               Read More
//             </Link>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div className="col-lg-3 col-md-6 mb-2">
//       <div className="card pt-4">
//         <h2 className="heading2 card-title text-center pb-2">ICSI</h2>
//         <img
//           src={img4}
//           style={{ height: "100px", width: "100px" }}
//           className="align-self-center card-img-top"
//           alt="sperm is injected into a egg"
//         />
//         <div className="card-body">
//           <p
//             className="card-text py-3"
//             style={{ textAlign: "justify" }}
//           >
//             ICSI, another type of assisted reproduction, improves the
//             chances of fertilization as the sperm is directly injected
//             into the egg.
//           </p>
//           <div className="text-center">
//             <Link
//               to="/treatments/intracytoplasmic"
//               className="btn btn-lg btn-primary btn-color-services"
//             >
//               Read More
//             </Link>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>;
